import React from 'react';
import HowItWorks from 'views/HowItWorks';
import { SEO as Head } from 'components/seo';

const HowItWorksPage = (): JSX.Element => {
  const seo = {
    title: 'How to real estate is tokenized',
    description:
      'Explore the process of real estate tokenization projects from purchasing the property, obtaining permits, construction process, tokenizing and offering it to investors.',
    pathname: '/how-it-works',
  };
  return (
    <>
      <Head
        title={seo.title}
        description={seo.description}
        pathname={seo.pathname}
      />
      <HowItWorks />
    </>
  );
};

export default HowItWorksPage;
