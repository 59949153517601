import React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/system';
import imageNr1 from 'images/howToSection/1.png';
import imageNr2 from 'images/howToSection/2.png';
import imageNr3 from 'images/howToSection/3.png';
import imageNr4 from 'images/howToSection/4.png';
import imageNr5 from 'images/howToSection/5.png';
import imageNr6 from 'images/howToSection/6.png';
import imageNr7 from 'images/howToSection/7.png';
import { StyledPropsFix } from 'types';
import { SiteMaxWithContext } from 'theme/siteMaxWithContext';

const content = [
  {
    id: 1,
    title: 'Purchasing property',
    content: `We collaborate with local real estate agencies in order to find the
    properties with the most growth potential. Our focus is on old,
    low-energy class properties with a very attractive location.`,
    image: imageNr1,
    alt: 'An illustration displaying house and representing property purchase process in Coreestate business',
  },
  {
    id: 2,
    title: 'Obtaining project and construction permits',
    content: `Our team of architects turns the existing property into a
    state-of-the-art rental apartment building with great
    living conditions.`,
    image: imageNr2,
    alt: 'Illustration showing obtaining construction permit from local authorities',
  },
  {
    id: 3,
    title: 'Property tokenization',
    content: `In order to raise funds for the construction of the new buildings, 
    we tokenize the property on Ethereum blockchain into digital shares for selling
     to investors. Digital shares are named after property addresses.`,
    image: imageNr3,
    alt: 'Illustration of investors buying Coreestate tokens',
  },
  {
    id: 4,
    title: 'Distribution of Coreestate issued tokens',
    content: `Unique verification on an Ethereum blockchain is used as proof of ownership for every transaction, 
      aka Smart Contracts. At this phase, the investors legally become co-owners of the property through 
      the purchase of the digital shares.`,
    image: imageNr4,
    alt: 'Illustration of man and woman becoming owners of real estate backed token through Coreestate investing platform',
  },
  {
    id: 5,
    title: 'Trading the Ownership via tokens',
    content:
      'When the funds are raised, investors can begin trading their tokens. Additionally, investors who missed the primary market can buy tokens from the existing token owners in the upcoming digital share exchanges using their whitelisted wallet. The token price is determined by the principles of supply and demand, with a limited number of tokens issued for each property.',
    image: imageNr5,
    alt: 'Illustration of investor relation trading asset backed token peer-to-peer eliminating intermediaries',
  },
  {
    id: 6,
    title: 'Constructing the apartment building',
    content: `Shortly after the funds are raised, we begin construction of the buildings. Our construction supervisors together 
      with the local builders build the buildings.`,
    image: imageNr6,
    alt: 'Illustration of construction process that starts after Coreestate digital share offering is successfully completed.',
  },
  {
    id: 7,
    title: 'Earning dividends through rent',
    content: `Once apartments are rented out, investors begin receiving quarterly dividends in their 
    whitelisted wallets relative to the amount they have invested in the tokens.`,
    image: imageNr7,
    alt: 'Illustration of coreestate tokens with like icon, mobile phone and finance pie chart.',
  },
];

const Title = styled(Typography)`
  ${({ theme }) => `
  font-family: Poppins SemiBold;

  ${theme.breakpoints.up('md')} {
   font-size: 2.1em;
   margin: 1em 0;
   line-height: 50px;
   order: initial;
  }
  ${theme.breakpoints.down('md')} {
   text-align: center;
   margin: 0 -5em;
  }
  ${theme.breakpoints.only('sm')} {
   font-size: 1.6em;
   margin-bottom: 1.0em;
   line-height: 40px;
  }
  ${theme.breakpoints.only('xs')} {
   font-size: 1.2em;
   margin: 0 0 1.0em 0;
   line-height: inherit;
  }
  `}
` as StyledPropsFix;

const InfoText = styled(Typography)`
  ${({ theme }) => `
  font-family: Roboto Regular;
  padding-bottom: 1.6em;
  color: ${theme.palette.text.primary};

  ${theme.breakpoints.up('md')} {
    font-size: 1.1em;
  }
  ${theme.breakpoints.down('md')} {
    font-size: 0.9em;
    text-align: center;
  }
  ${theme.breakpoints.only('xs')} {
  text-align: left;
  }
`}
` as StyledPropsFix;

const InfoBox = styled(Box)`
  ${({ theme }) => `
  text-align: left;
  align-self: start;

  ${theme.breakpoints.up('md')} {
   flex: 1.1;
   display: inline-flex;
   flex-direction: column;
   justify-content: center;
   align-self: auto;
   order: initial;
  }
  ${theme.breakpoints.down('md')} {
   flex-direction: column;
   display: flex;
   align-self: auto;
   order: 4;
   margin: 0 6em;
   padding-top: 1em;
  }
  ${theme.breakpoints.only('xs')} {
   margin: 0 0em;
  }
`}
`;

export const ContentContainer = styled(Container)<{ sizeofgrid?: string }>`
  ${({ theme, sizeofgrid }) => `
  height: 100;
  position: relative;

  ${theme.breakpoints.up('md')} {
    display: flex;
    margin: 4em auto;
    gap: 3.5em;
    align-items: initial;
    ${sizeofgrid && `max-width: ${sizeofgrid}px;`}
  }
  ${theme.breakpoints.down('md')} {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 4em auto;
  }
`}
`;

const ContentBox = styled(Box)`
  ${({ theme }) => `
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: ${theme.palette.background.paper}
  `}
`;

const PictureBox = styled(Box)`
  ${({ theme }) => `

  ${theme.breakpoints.up('md')} {
   display: inline-block;
   position: relative;
   width: 93em;
   order: initial;
   flex: 1;
  }

  ${theme.breakpoints.only('sm')} {
   display: flex;
   position: relative;
   width: 28rem;
   order: 1;
  }
  ${theme.breakpoints.only('xs')} {
   display: flex;
   position: relative;
   width: 20rem;
   order: 1;
  }
`}
`;
const Picture = styled(Box)`
  object-fit: contain;
` as StyledPropsFix;

const HowToSections = (): JSX.Element => {
  const [sizeOfGrid] = React.useContext(SiteMaxWithContext);

  return (
    <ContentBox>
      {content.map((card, index) => (
        <ContentContainer
          key={card.id}
          sx={{
            flexDirection: index % 2 === 0 ? 'row' : 'row-reverse',
            marginTop: index === 0 ? '0 !important' : '',
          }}
          sizeofgrid={sizeOfGrid?.toString()}
        >
          <InfoBox>
            <Title component="h1" variant="h4" color="text.primary">
              {card.title}
            </Title>
            <InfoText component="p">{card.content}</InfoText>
          </InfoBox>

          <PictureBox>
            <Picture
              src={card.image}
              component={'img'}
              alt={card.alt}
              height={1}
              width={1}
            />
          </PictureBox>
        </ContentContainer>
      ))}
    </ContentBox>
  );
};

export default HowToSections;
